import axios from "axios"
import React, { useState, useEffect } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Layout from "./../components/layout"

const Actualites = () => {

  const [articles, setArticles] = useState([])

  useEffect(() => {
    const apiEndPoint = "https://smartop-back-preprod.azurewebsites.net/api/"
    axios
      .get(apiEndPoint + "article/getArticles")
      .then(result => setArticles(result.data))
      .catch(() =>
        toast.error("Une erreur s'est produite, réessayer encore une fois!", {
          position: toast.POSITION.BOTTOM_CENTER
        })
      )
  }, [setArticles])

  const checkZero = data => {
    if (data.length == 1) {
      data = "0" + data
    }
    return data
  }

  const monthNames = [
    "janvier", "février", "mars",
    "avril", "mai", "juin",
    "juillet", "août", "septembre",
    "octobre", "novembre", "décembre"
  ];

  const formateDate = date => {
    const dateObject = new Date(date)
    var day = checkZero(dateObject.getDate() + "")
    var month = monthNames[dateObject.getMonth() + 1]

    var year = dateObject.getFullYear() + ""
    return `${day} ${month} ${year}`
  }

  const getSeparator = index => {
    if (articles.length - 1 !== index)
      return (<div className="separator"></div>)
    return null;
  }

  return (
    <Layout>
      <div className="container">
        <div className="actuality-container">
          <div className="header">
            <div className="title">Actualité</div>
            <div className="subtitle">Notre point de vue sur l’actualité médicale et notre réponse grace à SmartOp.
            </div>
          </div>
        </div>
        <div className="article-container">
          {articles.map(({ title, imgUrl, body, createdAt }, index) => {
            const createdAtFormatted = formateDate(createdAt)
            return (
              <div>
                <article>
                  <header>
                    <h1 className="title">{title}</h1>
                  </header>
                  <div>
                    <div className="subtitle">
                      Par Saad Laraichi, le {createdAtFormatted}
                    </div>
                    <img class="article-img" src={imgUrl} />
                    <div className="_description" dangerouslySetInnerHTML={{ __html: body }}></div>
                  </div>
                </article>
                {getSeparator(index)}
              </div>
            )
          })}
        </div>
      </div>

      <ToastContainer />
    </Layout>
  )
}
export default Actualites
